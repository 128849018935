import type { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query/react';
import { API, Auth } from 'aws-amplify';

import * as AllTypes from 'clipsal-cortex-types/src/api/index';

import { ENV_TYPE } from '../../env-type';
import getDynamicStubDataForRoute, { OtherPayloadTypes } from '../../features/demo-login/dynamic-route-helpers';
import { HTTPMethod, ROUTE_MAP } from '../../features/demo-login/route-map';
import { IS_DEMO_LOGIN } from '../constants';

/**
 * Performs a GET request to a provided name/resource path on the API.
 *
 * @param path The path from the end point resource
 */
export async function get<T>(path: string): Promise<T> {
  if (IS_DEMO_LOGIN) return handleStubbedAPIRequest<T>('GET', path);
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return await API.get('site', path, {
    headers,
  });
}

/**
 * Performs a POST request to a provided name/resource path on the API.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function post<T>(path: string, body?: OtherPayloadTypes): Promise<T> {
  if (IS_DEMO_LOGIN) return handleStubbedAPIRequest<T>('POST', path, body);
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'x-api-key': import.meta.env?.[`VITE_${ENV_TYPE}_API_KEY`] as string,
  };

  return await API.post('site', path, {
    headers,
    body,
  });
}

/**
 * Performs a POST request to a provided public name/resource path on the API that doesn't require auth.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function postPublic<T>(path: string, body?: OtherPayloadTypes): Promise<T> {
  if (IS_DEMO_LOGIN) return handleStubbedAPIRequest<T>('POST', path, body);
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'x-api-key': import.meta.env?.[`VITE_${ENV_TYPE}_API_KEY`] as string,
  };
  return await API.post('site', path, {
    headers,
    body,
  });
}

/**
 * Performs a PATCH request to a provided name/resource path on the API.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function patch<T>(path: string, body?: OtherPayloadTypes): Promise<T> {
  if (IS_DEMO_LOGIN) return handleStubbedAPIRequest<T>('PATCH', path, body);
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'x-api-key': import.meta.env?.[`VITE_${ENV_TYPE}_API_KEY`] as string,
  };

  return await API.patch('site', path, {
    headers,
    body,
  });
}

/**
 * Performs a DELETE request to a provided name/resource path on the API.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function del<T>(path: string, body?: OtherPayloadTypes): Promise<T> {
  if (IS_DEMO_LOGIN) return handleStubbedAPIRequest<T>('DELETE', path);
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'x-api-key': import.meta.env?.[`VITE_${ENV_TYPE}_API_KEY`] as string,
  };

  return await API.del('site', path, {
    headers,
    body,
  });
}

/**
 * Performs a PUT request to a provided name/resource path on the API.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function put<T>(path: string, body?: OtherPayloadTypes): Promise<T> {
  if (IS_DEMO_LOGIN) return handleStubbedAPIRequest<T>('PUT', path, body);
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'x-api-key': import.meta.env?.[`VITE_${ENV_TYPE}_API_KEY`] as string,
  };

  return await API.put('site', path, {
    headers,
    body,
  });
}

async function handleStubbedAPIRequest<T>(method: HTTPMethod, path: string, body?: OtherPayloadTypes): Promise<T> {
  /**
   * Accepts a method and route, and returns the appropriate stubbed response for the demo login.
   * Throws an exception if the route or method is invalid.
   *
   * @param method - The HTTP method to use.
   * @param route - The route to return data for.
   * @param body - [optional] The body of a PUT/PATCH/POST request, as an object.
   * @returns An object or array containing the response data.
   * @throws Error
   */
  function handleDemoLoginAPIRequest(method: HTTPMethod, route: string, body?: OtherPayloadTypes) {
    const dynamicRouteData = getDynamicStubDataForRoute(method, route, body);
    if (dynamicRouteData) return dynamicRouteData;
    if (ROUTE_MAP?.[method]?.[route]) return ROUTE_MAP[method][route];
    else {
      console.error(
        `Invalid stubbed API call: route '${route}' does not exist for method '${method}', or this method is invalid.`
      );
      throw new Error(
        `Invalid stubbed API call: route '${route}' does not exist for method '${method}', or this method is invalid.`
      );
    }
  }

  return new Promise((res) => {
    setTimeout(() => {
      res(handleDemoLoginAPIRequest(method, path, body) as T);
    }, 200);
  });
}

export type ApiFunction<T> = ((path: string, body: object) => Promise<T>) | ((path: string) => Promise<T>);
export type APIMethods = 'GET' | 'POST' | 'POST_PUBLIC' | 'DELETE' | 'PATCH' | 'PUT';
export type QueryArgs = string | (FetchArgs & { method: APIMethods });
type AllApiTypes = typeof AllTypes;

const mapApiMethodToApiFunction: Record<APIMethods, ApiFunction<AllApiTypes>> = {
  GET: get,
  POST: post,
  POST_PUBLIC: postPublic,
  DELETE: del,
  PATCH: patch,
  PUT: put,
};

export type ErrorResponse = {
  detail: string;
  code: string;
  status_code: number;
};

export type NetworkError = {
  status: number;
  response?: {
    status: number;
    data: ErrorResponse;
  };
  message: string;
};

export type AuthError = {
  response?: {
    name: string;
  };
  name: string;
};

export type RTKQError = {
  status: number;
  message: string;
  originalError: NetworkError;
};

export const customBaseQuery: BaseQueryFn<QueryArgs, unknown, RTKQError> = async (args) => {
  try {
    const url = typeof args === 'string' ? args : args.url;
    const method = typeof args === 'string' ? 'GET' : args.method;
    const body = typeof args === 'string' ? undefined : args.body;
    const apiFunction = mapApiMethodToApiFunction[method || 'GET'];
    const data = await apiFunction(url, body);
    return { data };
  } catch (e: unknown) {
    const error = e as NetworkError;
    return {
      error: {
        status: error?.status ?? error?.response?.status ?? 500,
        message: error?.message ?? error?.response?.data?.detail ?? '',
        originalError: error,
      },
    };
  }
};
