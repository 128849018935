import { Amplify, API } from 'aws-amplify';

import { LocalForageStorage } from '../../common/classes/local-forage';
import { CLIENT_ID, ENDPOINT, USER_POOL_ID } from '../../common/constants';

/**
 * Configures the AWS Amplify end-point setup for this repo.
 *
 * Note that we need to separately configure the `API` class, as for some reason in this monorepo architecture it does
 * not correctly initialize itself.
 *
 * see: https://github.com/aws-amplify/amplify-js/issues/5756
 */
export function setupAPI() {
  const apiConfig = {
    endpoints: [
      {
        name: 'site',
        endpoint: ENDPOINT,
        region: 'ap-southeast-2',
      },
    ],
  };

  const authOpts: Record<string, string | LocalForageStorage> = {
    region: 'ap-southeast-2',
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: CLIENT_ID,
    authenticationFlowType: 'CUSTOM_AUTH',
  };

  // Use default storage if we're testing with Cypress
  if (!window.Cypress) {
    authOpts.storage = new LocalForageStorage();
  }

  Amplify.configure({
    Auth: authOpts,
    API: apiConfig,
  });
  API.configure(apiConfig);
}
