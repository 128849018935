import React, { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';

import TopNav from '../../common/components/TopNav';
import { useViewportType } from '../../common/hooks/use-viewport-type';

type ProfilePageBaseProps = {
  title: string;
  backUrl?: string;
  containerProps?: BoxProps;
} & PropsWithChildren;

const ProfilePageBase = ({ children, title, backUrl, containerProps }: ProfilePageBaseProps) => {
  const navigate = useNavigate();
  const { isDesktopViewport } = useViewportType();

  return (
    <Box height={'100vh'} data-testid={`${title.replaceAll(' ', '-').toLowerCase()}-page`} {...containerProps}>
      <TopNav
        title={title}
        onClickBack={() => (backUrl ? navigate(backUrl) : navigate(-1))}
        shouldDisplayTitleOnDesktop
      />

      <Box mt={isDesktopViewport ? 3 : TOP_NAV_SPACING_AFFORDANCE}>{children}</Box>
    </Box>
  );
};

export default ProfilePageBase;
