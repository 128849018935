import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQuery } from '../../common/api/api-helpers';

export const BILLS_TAGS = [
  'BillsStatus',
  'Tariffs',
  'Tariff',
  'Retailers',
  'RetailerLogo',
  'RetailPlanCheck',
  'BillForecast',
  'BillPeriodCosts',
  'AmberSites',
  'AmberConnection',
  'TariffTemplates',
] as const;

const PROFILE_TAGS = [
  'Articles',
  'NearmapImage',
  'EECMSMEligibility',
  'StatsToDate',
  'Referral',
  'BaseLoad',
  'AlwaysOnLoads',
] as const;

const LIVE_TAGS = [
  'Switches',
  'SwitchSchedules',
  'SiteDeviceSchedules',
  'SiteSolarSpongeConfig',
  'SensiboDevice',
  'SensiboDevices',
  'TeslaAccountConnection',
  'EVChargers',
  'EVChargerCurrent',
  'LiveData',
  'Weather',
  'CurrentEnergyRates',
] as const;

const USAGE_TAGS = ['Usage', 'SunriseSunset', 'ApplianceMap', 'Storage', 'UsageSummary', 'Costs'] as const;

export const baseApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
  tagTypes: [...BILLS_TAGS, ...PROFILE_TAGS, ...LIVE_TAGS, ...USAGE_TAGS],
});

export const {} = baseApi;
