import React from 'react';
import { Box, HStack, useColorModeValue } from '@chakra-ui/react';

import ProfilePageBase from '../ProfilePageBase';
import { AmberIntegration, SensiboIntegration, TeslaAccountConnection } from './ThirdPartyIntegrations';

export default function Integrations() {
  const backgroundColor = useColorModeValue('white', 'gray.900');
  const borderColor = useColorModeValue('gray.300', 'black');
  const hoverColor = useColorModeValue('whiteAlpha.700', 'black');
  return (
    <ProfilePageBase title={'Integrations'}>
      <Box background={backgroundColor}>
        <HStack borderBottom="1px solid" borderColor={borderColor} px={3} minH={100} pt={4} _hover={{ bg: hoverColor }}>
          <TeslaAccountConnection />
        </HStack>
        <HStack borderBottom="1px solid" borderColor={borderColor} px={3} minH={100} _hover={{ bg: hoverColor }}>
          <SensiboIntegration />
        </HStack>
        <HStack px={3} minH={100} _hover={{ bg: hoverColor }}>
          <AmberIntegration />
        </HStack>
      </Box>
    </ProfilePageBase>
  );
}
