import {
  APISchedule,
  Appliance,
  ArticleData,
  Bill,
  BillForecast,
  BillsStatus,
  CurrentEnergyRates,
  EvChargerCurrentData,
  EvChargerStatusData,
  Investments,
  LiveWeatherData,
  Milestone,
  Retailer,
  RPCData,
  SiteDetailData,
  SiteDeviceSchedule,
  SiteSolarSpongeConfig,
  SiteTariff,
  Switch,
  TariffTemplate,
  TeslaConnection,
  Tip,
  TotalCostsData,
  UserData,
  ZendeskUser,
} from 'clipsal-cortex-types/src/api';
import { PaginatedResponseV2 } from 'clipsal-cortex-types/src/common/types';
import { formatDate } from 'clipsal-cortex-utils/src/formatting/formatting';

import { AllResponseTypes } from './dynamic-route-helpers';
import appliances from './stubs/appliances.json';
import articles from './stubs/articles.json';
import billForecast from './stubs/bill_forecast.json';
import billsStatus from './stubs/bills_status.json';
import bills from './stubs/bills.json';
import evChargerCurrent from './stubs/ev_charger_current.json';
import evChargerStatus from './stubs/ev_charger_status.json';
import investments from './stubs/investments.json';
import milestones from './stubs/milestones.json';
import totalCost from './stubs/new_total_costs.json';
import retailers from './stubs/retailers.json';
import rpc from './stubs/rpc.json';
import schedules from './stubs/schedules.json';
import siteSolarSpongeConfig from './stubs/site_solar_sponges.json';
import demoSite from './stubs/site.json';
import switches from './stubs/switches.json';
import tariffTemplate from './stubs/tariff_template.json';
import tariffs from './stubs/tariffs.json';
import teslaConnection from './stubs/tesla_connection.json';
import tips from './stubs/tips.json';
import user from './stubs/user.json';
import weather from './stubs/weather.json';
import { CURRENT_DAY_IN_TIMEZONE, TOTAL_NUM_DAYS } from './time-series-helpers';

function getDemoSiteToday(): SiteDetailData {
  const numDaysToMoveBack = TOTAL_NUM_DAYS - 1;
  const site = demoSite as unknown as SiteDetailData;

  const date = new Date(CURRENT_DAY_IN_TIMEZONE);
  date.setDate(date.getDate() - numDaysToMoveBack);
  site.monitoring_start = `${formatDate(date)} 00:00:00`;

  return site;
}

export const RETRIEVE_ROUTE_MAP: Record<string, AllResponseTypes> = {
  '/v1/user': user as UserData,
  '/v1/sites/1': getDemoSiteToday(),
  '/v1/sites/1/investments': investments as Investments,
  '/v1/articles': articles as ArticleData[],
  '/v1/sites/1/tips': tips as Tip[],
  '/v1/sites/1/total_cost': totalCost as TotalCostsData,
  '/v1/sites/1/tesla_connection': teslaConnection as TeslaConnection,
  '/v1/sites/1/switches': switches as Switch[],
  '/v1/zendesk_user': { id: 1 } as ZendeskUser,
  '/v1/tickets': [],
  '/v1/sites/1/rpc': rpc as RPCData,
  '/v1/sites/1/bills_status': billsStatus as BillsStatus,
  '/v2/sites/1/tariffs?offset=0&limit=5': tariffs as PaginatedResponseV2<SiteTariff>,
  '/v2/sites/1/tariffs': tariffs as PaginatedResponseV2<SiteTariff>,
  '/v1/sites/1/bill_forecast': billForecast as BillForecast,
  '/v1/sites/1/bills': bills as Bill[],
  '/v1/retailers?country_code=AU': retailers as PaginatedResponseV2<Retailer>,
  '/v1/sites/1/milestones': milestones as Milestone[],
  '/v1/sites/1/appliances': appliances as Appliance[],
  '/v1/switches/1/schedules': schedules as APISchedule[],
  '/v1/sites/1/current_rates': [] as CurrentEnergyRates[],
  '/v1/sites/1/weather': weather as LiveWeatherData,
  '/v1/site_devices/1640/schedules': [] as SiteDeviceSchedule[],
  '/v1/ev_chargers/1640/status': evChargerStatus as EvChargerStatusData,
  '/v1/ev_chargers/1640/charge_current': evChargerCurrent as EvChargerCurrentData,
  '/v1/tariff_templates?distributor_id=1&retailer_id=36': tariffTemplate as TariffTemplate[],
  '/v1/sites/1/solar_sponges': siteSolarSpongeConfig as SiteSolarSpongeConfig,
};

export type HTTPMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

export const ROUTE_MAP: Record<HTTPMethod, Record<string, AllResponseTypes>> = {
  GET: RETRIEVE_ROUTE_MAP,
  POST: {},
  PATCH: {},
  DELETE: {},
  PUT: {},
};
